/* Dark grey background for the entire app */
body, html, #root, .App {
  background-color: #2c2c2c;
  color: #161515;
  height: 100%;
  width: 100%;
  
  margin: 0;
}

/* Container to center the logo in the background */
.background-logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.05; /* Subtle, watermark-like opacity */
  z-index: -1; /* Behind all content */
}

/* Ensure the main content stays above the logo */
.main-content {
  position: relative;
  z-index: 1;
  padding: 20px; /* Add padding for better spacing */
  background-color: rgba(44, 44, 44, 0.9); /* Slightly transparent dark grey background */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
}

  


/* Styling for the AppBar */
.MuiAppBar-root {
  background-color: #333;
}

/* Styling for the Drawer */
.MuiDrawer-paper {
  background-color: #1e1e1e;
}

/* Transitions for page changes */
.page-enter {
  opacity: 0;
  transform: translateX(50%);
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms ease, transform 300ms ease;
}
.page-exit {
  opacity: 1;
  transform: translateX(0);
}
.page-exit-active {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 300ms ease, transform 300ms ease;
}

/* Ensures the main content stays above the logo */
.main-content {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: auto;  /* Ensures the content scrolls if it's too tall */
}


/* Dashboard container */
.dashboard-container {
  padding: 24px;
  background-color: #f4f6f8;
}

/* Dashboard card */
.dashboard-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.analytics-container {
  background-color: #2e2e2e;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.analytics-container:hover {
  background-color: #333;
  transform: scale(1.02);
}

.analytics-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.metrics-section {
  margin-bottom: 20px;
}

.metrics-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
  border-bottom: 1px solid #555;
  padding-bottom: 5px;
}

.metrics-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.metric-item {
  background-color: #3c3c3c;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.metric-item:hover {
  background-color: #444;
  transform: translateX(5px);
}

.metric-label {
  font-weight: bold;
}

.metric-value {
  color: #ccc;
}

.export-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.export-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
