/* Dashboard.css */

/* General Styling */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    background-color: #f4f6f8; /* Light background for the main page */
  }
  
  #root {
    height: 100vh;
    display: flex;
  }
  
  /* AppBar Styling */
  .MuiAppBar-root {
    background-color: #1976d2; /* Dark blue color for AppBar */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .MuiAppBar-root img {
    height: 50px; /* Logo size */
    margin-right: 16px;
  }
  
  .MuiAppBar-root button {
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .MuiAppBar-root button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /* Drawer Styling */
  .MuiDrawer-paper {
    background-color: #ffffff; /* White background for Drawer */
    border-right: 2px solid #e0e0e0; /* Light border on the right */
  }
  
  .MuiList-root {
    padding-top: 0;
  }
  
  .MuiListItem-root {
    margin: 8px 0;
    padding: 12px 24px;
    border-radius: 12px;
    transition: background-color 0.3s ease;
    color: #333; /* Default text color */
  }
  
  .MuiListItem-root:hover {
    background-color: #f0f4ff; /* Light blue hover effect */
  }
  
  .MuiListItemText-primary {
    font-size: 16px; /* Font size for list items */
    font-weight: 500;
    color: #1976d2; /* Blue color for text */
  }
  
  /* Main Content Area */
  .MuiBox-root {
    background-color: #ffffff; /* White background for content area */
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    padding: 32px;
    /* overflow-y: auto; */
 }
  
  .MuiBox-root h1,
  .MuiBox-root h2,
  .MuiBox-root h3 {
    color: #333333; /* Darker color for headings */
    font-weight: 700; /* Bold font */
  }
  
  .MuiBox-root h1 {
    font-size: 32px; /* Large font for main headings */
  }
  
  .MuiBox-root h2 {
    font-size: 28px; /* Slightly smaller font */
  }
  
  .MuiBox-root h3 {
    font-size: 24px; /* Smaller font for subheadings */
  }
  
  /* Customizing Buttons inside the main content */
  button {
    background-color: #1976d2;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #1565c0; /* Slightly darker blue on hover */
  }
  
  /* Scrollbar Styling */
  .MuiBox-root::-webkit-scrollbar {
    width: 8px;
  }
  
  .MuiBox-root::-webkit-scrollbar-thumb {
    background-color: #1976d2; /* Blue scroll thumb */
    border-radius: 8px;
  }
  
  .MuiBox-root::-webkit-scrollbar-track {
    background-color: #f4f6f8; /* Light background for scroll track */
  }
  
  /* Card Styling for each component in content area */
  .component-card {
    background-color: #ffffff; /* White background */
    padding: 24px;
    margin-bottom: 16px;
    border-radius: 16px; /* Rounded corners */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .component-card:hover {
    transform: translateY(-4px); /* Slight lift on hover */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  }
  
  /* Component Heading */
  .component-heading {
    font-size: 24px;
    color: #1976d2; /* Blue color for headings */
    margin-bottom: 16px;
    font-weight: 600;
  }
  
  /* Additional Elements */
  .icon-wrapper {
    display: flex;
    align-items: center;
    background-color: #e3f2fd; /* Light blue background for icons */
    padding: 8px;
    border-radius: 50%;
    margin-right: 16px;
  }
  
  .icon-wrapper svg {
    color: #1976d2; /* Blue color for icons */
    font-size: 24px;
  }
  
  /* Custom Text Fields */
  .custom-textfield {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .custom-textfield input {
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .custom-textfield input:focus {
    border-color: #1976d2; /* Blue border on focus */
    outline: none;
    box-shadow: 0px 0px 4px rgba(25, 118, 210, 0.2); /* Light shadow */
  }
  
  /* Custom Select */
  .custom-select {
    width: 100%;
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  
  .custom-select:focus {
    border-color: #1976d2; /* Blue border on focus */
    box-shadow: 0px 0px 4px rgba(25, 118, 210, 0.2); /* Light shadow */
    outline: none;
  }
  