/* Main container for the video list */
.video-list-container {
    max-width: 800px;
    margin: 40px auto 0; /* Added top margin */
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Heading style */
.video-list-container h1 {
    margin: 0;
    color: #333333d7;
}

/* Scrollable container for the video items */
.video-list-scroll {
    max-height: 400px; /* Set a max height for scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Individual video item */
.video-item {
    padding: 15px;
    border-bottom: 1px solid #eee; /* Light gray bottom border */
    display: flex; /* Flex layout for video item */
    justify-content: space-between; /* Space between title and button */
    align-items: center; /* Center items vertically */
}

/* Video title */
.video-item h4 {
    margin: 0; /* Remove default margin */
    color: #444; /* Darker text color for title */
}

/* Video actions */
.video-actions {
    display: flex;
    gap: 10px;
}

/* Video action buttons */
.video-actions button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

/* Video action button styles */
.video-actions button:nth-child(1) {
    background-color: #28a745;
    color: white;
}

.video-actions button:nth-child(2) {
    background-color: #ffc107;
    color: black;
}

.video-actions button:nth-child(3) {
    background-color: #dc3545;
    color: white;
}

/* Video player styling */
.video-player {
    margin-top: 20px; /* Space above player */
    text-align: center; /* Center the content */
}

/* Edit video form styling */
.edit-video-form {
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Input fields for the edit form */
.edit-video-form input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* New styles for edit form buttons */
.edit-form-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 10px; /* This adds space between the buttons */
    margin-top: 10px;
}

.edit-form-btn {
    padding: 6px 12px; /* Reduced padding for smaller buttons */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.save-btn {
    background-color: #28a745;
    color: white;
}

.cancel-btn {
    background-color: #dc3545;
    color: white;
}

/* Delete confirmation dialog styling */
.delete-confirmation {
    margin-top: 20px; /* Space above confirmation dialog */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center; /* Center the text */
}

/* Confirmation buttons */
.delete-confirmation button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
}

/* Loading message */
.loading {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #666;
}
