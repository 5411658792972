/* Full height and width layout */
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f8;
}

/* Container to center content vertically and horizontally */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

/* Box for login and registration forms */
.form-box {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px; /* Ensure a fixed max width for the form */
  box-sizing: border-box; /* Ensures padding doesn't affect the width */
}

/* Form field styling (shared between login and register) */
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

input {
  width: 100%;  /* Full width */
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box; /* Ensures padding doesn't overflow */
}

/* Submit button styling (shared) */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;  /* Full width */
}

button:hover {
  background-color: #0056b3;
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 20px;
}

/* Title styling (shared) */
h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
}

/* Registration link styling */
.registration-link {
  margin-top: 20px;
  text-align: center;
}

.registration-link a {
  color: #007bff;
  text-decoration: none;
}

.registration-link a:hover {
  text-decoration: underline;
}

/* Adjustments for smaller screens (both forms) */
@media (max-width: 768px) {
  .form-box {
    padding: 20px;
    max-width: 90%;
  }

  h1 {
    font-size: 24px;
  }

  input, button {
    font-size: 14px;
  }
}
