.appointment-management {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h2 {
  margin: 0;
  color: #333;
}

.appointment-table-container {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.appointment-table {
  width: 100%;
  border-collapse: collapse;
}

.appointment-table th,
.appointment-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.appointment-table th {
  background-color: #f8f8f8;
  font-weight: bold;
  color: #333;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
}

.status-badge.confirmed {
  background-color: #e7f3e8;
  color: #4CAF50;
}

.status-badge.pending {
  background-color: #fff3e0;
  color: #ff9800;
}

.status-badge.cancelled {
  background-color: #ffebee;
  color: #f44336;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.action-buttons button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.edit-btn {
  background-color: #2196F3;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.loading {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}
